import React, { useContext, useEffect, useRef, useState } from "react";
import Loading from "../components/common/Loading";
import { toast } from "react-toastify";
import { httpReauest } from "../utils/httpRequest";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Countdown from "react-countdown";

const LoginPage = () => {
  const [name, setName] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState();
  const [code, setcode] = useState();
  const [step, setstep] = useState(false);
  const [time, settime] = useState(new Date());

  const { user, setUser, setAdmin } = useContext(AuthContext);

  async function handleSubmit(e) {
    e.preventDefault();
    if (!name && !password) {
      return toast.error("فیلد خالی وجود دارد");
    }
    setLoading(true);
    await httpReauest("POST", "/admin/login", { email: name, password })
      .then(async (res) => {
        if (res.status == 201) {
          setstep(true);
          setLoading(false);
          settime(new Date(time.getTime() + 120000));
          setUser(res.data.data);
          setAdmin(res.data.data);
          navigate("/product");
        }
      })
      .catch((data) => {
        setLoading(false);
        toast.error(data?.response?.data?.message);
      });
  }

  const navigate = useNavigate();

  // async function handleAcceptCode(e) {
  //   e.preventDefault();
  //   setLoading(true);

  //   await httpReauest("POST", "/admin/login/accept", { email: name, code })
  //     .then(async (res) => {
  //       if (res.status == 201) {
  //         toast.success("موفقیت امیز");

  //       }
  //     })
  //     .catch((data) => {
  //       setLoading(false);
  //       toast.error(data?.response?.data?.message);
  //     });
  // }

  // async function handleRepetae() {
  //   await httpReauest("POST", "/admin/login", { email: name, password })
  //     .then(async (res) => {
  //       if (res.status == 201) {
  //         setstep(true);
  //         setLoading(false);
  //         settime(new Date(time.getTime() + 120000));
  //         setTimeout(() => {
  //           ref.current.start();
  //         }, 1000);
  //         toast.success("کد ارسال شد");
  //       }
  //     })
  //     .catch((data) => {
  //       setLoading(false);
  //       toast.error(data?.response?.data?.message);
  //     });
  // }

  // const renderer = ({ hours, minutes, seconds, completed, days }) => {
  //   if (completed) {
  //     return (
  //       <button
  //         onClick={handleRepetae}
  //         type="button"
  //         className=" text-[#000] flex gap-2 font-[700] mt-2"
  //       >
  //         ارسال مجدد
  //       </button>
  //     );
  //   }
  //   return (
  //     <span className=" text-[#F42A1C] flex gap-2 font-[700] mt-2">
  //       تا ارسال مجدد : {minutes}:{seconds}
  //     </span>
  //   );
  // };

  const ref = useRef();

  return (
    <div
      dir="rtl"
      className="w-[100vw] h-[100vh] flex justify-center items-center"
    >
      <div className="border-brand px-10 py-10">
        <h1 className="title mb-8">شهاب کمپینگ پنل</h1>
        {/* {step ? (
          <form onSubmit={handleAcceptCode} className="flex flex-col gap-8">
            <p>کد تایید برای شماره تلفن ثبت شده ارسال شده ان را وارد کنید</p>
            <span className="relative w-full">
              <input
                id="code"
                name="code"
                value={code}
                onChange={(e) => setcode(e.target.value)}
                className="w-full form-brand min-w-[300px] h-[58px]"
              />
              <p className="on-border font-bold">کد تایید</p>
              <Countdown ref={ref} renderer={renderer} date={time} />
            </span>

            <span>
              {loading ? (
                <Loading />
              ) : (
                <button type="submit" className="btn-brand w-full">
                  ورود
                </button>
              )}
            </span>
          </form>
        ) : ( */}
        <form onSubmit={handleSubmit} className="flex flex-col gap-12">
          <span className="relative w-full">
            <input
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full form-brand min-w-[300px] h-[58px]"
            />
            <p className="on-border font-bold">نام کاربری</p>
          </span>
          <span className="relative w-full">
            <input
              value={password}
              id="password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              className="w-full form-brand min-w-[300px] h-[58px]"
              type="password"
            />
            <p className="on-border font-bold">رمز عبور</p>
          </span>
          <span>
            {loading ? (
              <Loading />
            ) : (
              <button type="submit" className="btn-brand w-full">
                ورود
              </button>
            )}
          </span>
        </form>
        {/* )} */}
      </div>
    </div>
  );
};

export default LoginPage;
