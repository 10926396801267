import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import { httpReauest } from "../../../utils/httpRequest";
import * as Yup from "yup";
import { BASE_URL, CDN_BASE_URL } from "../../../config";
import Loading from "../Loading";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import moment from "moment/moment";

const EditEventForm = () => {
  const [img, setImg] = useState();
  const [images, setImages] = useState([]);
  const [previews, setPreview] = useState([]);

  const [previewImg, setPreviewImg] = useState();
  const [vehicles, setVehicles] = useState();
  const [selectedVehicle, setselectedVehicle] = useState();
  const [loading, setloading] = useState();
  const [file, setfile] = useState();


  const toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      name: Yup.string().required("الزامی است"),
      time: Yup.date().required("الزامی است"),
     
    });
  };

  async function deleteImg(index) {
    setPreview((students) => students.filter((s, i) => i != index));
    setImages((students) => students.filter((s, i) => i != index));
  }

  const { user, setUser } = useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      name: "",
      time: '',
      location: "",
      date:""
      
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      setloading(true);

      const formdata = new FormData();
      formdata.append("name", values.name);
      
        formdata.append("time", values.time);
      
      formdata.append("location", values.location);
      

      if (img) {
        formdata.append("img", img, img?.name);
      }
      

      

      await fetch(BASE_URL + "/event/" + selectedVehicle?._id, {
        method: "PUT",
        body: formdata,
        headers: { "x-access-token": user.token },
      }).then(async (dat) => {
        if (dat?.status == 201) {
          toast.success("رویداد تغییر یافت");
          setloading(false);
          formik.resetForm();
          setImg(null);
        
          setPreviewImg(null);
          
        
        } else {
          setloading(false);
          const message = await dat.json();
          toast.error(message?.message);
        }
      });
    },
  });

  useEffect(() => {
    getData();
  }, []);

  function handleImg(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg(fils);
      setPreviwImage(fils);
    }
  }

 

  

  async function setPreviwImage(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  async function getData() {
    setloading(true);

    // await httpReauest("GET", "/url", {}, {}).then(({ data }) => {
    //   seturls(data?.data);
    // });

    await httpReauest("GET", "/event", {}, {}).then(
      ({ data }) => {
        setVehicles(data?.data);
      }
    );
    setloading(false);
  }

  async function handleChange(e) {
    setImages([]);
    setPreview([]);
    const { data } = await httpReauest(
      "GET",
      "/event/" + e.target.value,
      {},
      {}
    );

    const newVehicle = data?.data;

    setselectedVehicle(newVehicle);
    
    
    formik.setFieldValue("name", newVehicle.name);
    formik.setFieldValue("time", new Date(newVehicle.time) );
    formik.setFieldValue("location", newVehicle.location);
    formik.setFieldValue("date", newVehicle.date);
    

    setPreviewImg(CDN_BASE_URL + newVehicle?.img);

  
  }

  async function handleDelete(params) {
    await fetch(BASE_URL + "/event/" + selectedVehicle?._id, {
      method: "DELETE",
      headers: { "x-access-token": user.token },
    })
      .then((dat) => {
        if (dat?.status == 201) {
          toast.success("رویداد حذف شد");
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  }

  console.log(previewImg)
  // function handleChangetime(params) {
  //   const value = params.target.value;
  //   const formattedValue = (
  //     Number(value.replace(/\D/g, "")) || ""
  //   ).toLocaleString();
  //   formik.setFieldValue("time", formattedValue);
  // }

  // function handleChangeUrl(params) {
  //   const value = params.target.value;
  //   setdetails([]);
  //   formik.setFieldValue("details", []);
  //   const finded = urls.find((item) => item._id === value);

  //   finded?.details?.map((item) => {
  //     setdetails((cur) => [...cur, { name: item, value: "" }]);
  //     formik.values.details.push({ name: item, value: "" });
  //   });
  //   formik.setFieldValue("url", value);
  // }
console.log(formik.values.time)
  return (
    <div>
      <h2 className="text-[20px] mb-8 font-bold p-3">فرم تغییر رویداد</h2>
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-6"
      >
        <span className="relative">
          <select
            id="vehicle"
            name="vehicle"
            onChange={handleChange}
            className={` w-full form-brand h-[48px]`}
          >
            <option>{loading && <Loading />}</option>
            {vehicles?.map((e) => {
              return (
                <option value={e._id}>
                  {e.name}
                </option>
              );
            })}
          </select>
          <p className="on-border font-bold">انتخاب رویداد*</p>
        </span>
        {selectedVehicle && (
          <>
            <span className="relative">
              <input
                placeholder="مثلا : فوتون 450"
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full form-brand h-[48px]"
              />
              {formik.errors.name && formik.touched.name && (
                <small style={{ color: "red", fontSize: "14px" }}>
                  {formik.errors.name}
                </small>
              )}
              <p className="on-border font-bold">نام رویداد*</p>
            </span>
            {/* <span className="relative">
              <select
                id="url"
                name="url"
                value={formik.values.url}
                onChange={handleChangeUrl}
                onBlur={formik.handleBlur}
                type="text"
                className="w-full form-brand h-[48px]"
              >
                <option></option>
                {urls?.map((e) => (
                  <option value={e._id}>
                    {e?.category?.name}/{e?.brand?.name}/{e?.name}
                  </option>
                ))}
              </select>
              <p className="on-border font-bold">url*</p>
            </span> */}
            <span className="relative">
              <input
                placeholder="مثلا : 4.350"
                id="time"
                name="time"
                value={formik.values.time}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="date"
                className="w-full form-brand h-[48px]"
              />
              <small>{moment(formik.values.time).format('DD/MM/YYYY') } زمان انتخاب شده</small>
              {formik.errors.time && formik.touched.time && (
                <small style={{ color: "red", fontSize: "14px" }}>
                  {formik.errors.time}
                </small>
              )}
              
              <p className="on-border font-bold">تاریخ*</p>
            </span>
            {/* <span className="relative">
              <input
                placeholder="مثلا : 1402"
                type="number"
                id="year"
                name="year"
                value={formik.values.year}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full form-brand h-[48px]"
              />
              {formik.errors.year && formik.touched.year && (
                <small style={{ color: "red", fontSize: "14px" }}>
                  {formik.errors.year}
                </small>
              )}
              <p className="on-border font-bold">سال ساخت*</p>
            </span> */}

            {/* <span className="relative">
              <span className="flex justify-between">
                <p className=" font-bold">
                  نمره کارشناسی{" "}
                  <button
                    type="button"
                    onClick={() => formik.setFieldValue("rate", 0)}
                    className="bg-black text-white p-1 rounded-[5px]"
                  >
                    کارشناسی نشده
                  </button>
                </p>
                <p>{formik.values?.rate}</p>
              </span>

              <input
                placeholder="مثلا : 1402"
                type="range"
                id="rate"
                name="rate"
                min={0}
                max={100}
                value={formik.values.rate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className=" w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer "
              />
            </span> */}

            {/* <span className="flex justify-between items-start">
              <span className="text-[#2E3132] text-[16px] font-[500]">
                فروش فوری است؟
              </span>
              <label class="inline-flex items-center cursor-pointer">
                <input
                  id="onFire"
                  name="onFire"
                  value={formik.values.onFire}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="checkbox"
                  class="sr-only peer"
                />
                <div class="relative w-11 h-[25px] bg-white peer-focus:outline-none rounded-full peer border peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full border-black after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-black after:border-gray-300 after:border after:rounded-full after:h-[19px] after:w-[19px] after:transition-all dark:border-gray-600 after:peer-checked:bg-white peer-checked:bg-black"></div>
              </label>
            </span> */}
            {/* <span className="relative">
              <select
                id="fit"
                name="fit"
                value={formik.values.fit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={` w-full form-brand h-[48px]`}
              >
                <option></option>
                <option>خودرو اقتصادی</option>
                <option>خودرو کاری</option>
              </select>
              <p className="on-border font-bold">مناسب؟</p>
            </span> */}
            {/* <span className="relative">
              <select
                id="lazing"
                name="lazing"
                value={formik.values.lazing}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={` w-full form-brand h-[48px]`}
              >
                <option></option>
                <option>لیزینگ دولتی</option>
                <option>لیزینگ خصوصی</option>
                <option>لیزینگ نمایشگاه</option>
              </select>
              <p className="on-border font-bold">نوع لیزینگ؟* </p>
              {formik.errors.lazing && formik.touched.lazing && (
                <small style={{ color: "red", fontSize: "14px" }}>
                  {formik.errors.lazing}
                </small>
              )}
            </span> */}

            {/* <span>
              <p className="mb-3 font-bold">فایل برای چک لیست</p>
              <input
                onChange={(e) => {
                  setfile(e.target.files[0]);
                }}
                type="file"
                className="mb-2"
              />
              {file && (
                <Link
                  target="_blank"
                  className="btn-brand w-fit"
                  to={file?.name}
                >
                  مشاهده فایل کنونی
                </Link>
              )}
            </span> */}
            <label
              htmlFor="img"
              className="relative w-full border-dashed border rounded-[10px] p-3 h-[200px] cursor-pointer border-[#444]"
            >
              {previewImg ? (
                <img
                  src={previewImg}
                  className="w-full h-full object-contain"
                  alt=""
                />
              ) : (
                <FaPlus className="center" size={30} />
              )}

              <p className="on-border font-bold">تصویر اصلی</p>
            </label>

            <input
              onChange={(e) => {
                handleImg(e);
              }}
              type="file"
              className="hidden"
              id="img"
              accept="image/png, image/jpg, image/jpeg"
            />
            {/* <span className="col-span-full">
              <h2 className="text-[18px]  font-bold p-3">مشخصات</h2>
            </span> */}
            {/* {details.length
              ? details?.map((item, index) => (
                  <span className="relative">
                    <input
                      id={`details[${index}].value`}
                      name={`details[${index}].value`}
                      value={formik.values.details[index]?.value}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="w-full form-brand h-[48px]"
                    />
                    <p className="on-border font-bold">{item.name}</p>
                  </span>
                ))
              : ""} */}
            <span className="relative col-span-full lg:col-span-1">
              <input
                id="location"
                name="location"
                value={formik.values.location}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full form-brand min-h-[48px]"
              />
              <p className="on-border font-bold">مکان</p>
            </span>
            <span className="relative col-span-full lg:col-span-1">
              <input
                id="date"
                name="date"
                value={formik.values.date}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full form-brand min-h-[48px]"
              />
              <p className="on-border font-bold">زمان</p>
            </span>
            
            <span className="col-span-full flex gap-4">
              {loading ? (
                <Loading />
              ) : (
                <>
                  <button className="btn-brand">تغییر رویداد</button>
                  <button
                    type="button"
                    onClick={handleDelete}
                    className=" btn-brand-outline"
                  >
                    {" "}
                    حذف رویداد
                  </button>
                </>
              )}
            </span>
          </>
        )}
      </form>
    </div>
  );
};

export default EditEventForm;