import React, { useContext, useEffect, useState } from "react";
import { FaAlignJustify, FaAngleLeft, FaSquare, FaTimes } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { httpReauest } from "../../utils/httpRequest";
import { AuthContext } from "../../context/AuthContext";

const Navbar = ({ admin }) => {
  const [show, setShow] = useState();
  const { user, setUser } = useContext(AuthContext);

  async function handleDelete() {
    await httpReauest("POST", "/admin/logout", user, {}).then(async () => {
      window.location.href = window.origin;
    });
  }

  if (admin) {
    return (
      <>
        <span className="fixed top-0  w-full h-12 p-3 bg-white z-20 col-span-full lg:hidden">
          <FaAlignJustify
            size={20}
            className=""
            onClick={() => setShow(true)}
          />
        </span>
        <div
          className={`lg:min-h-[100vh] bg-white w-full h-full border-l z-30 shadow-2xl flex absolute flex-col p-4 lg:relative ${
            show ? "left-0 " : "left-full hidden lg:block lg:left-0"
          } duration-200 `}
        >
          <span>
            <FaTimes
              size={20}
              className="lg:hidden"
              onClick={() => setShow(false)}
            />
          </span>

          <img className="w-[120px] mx-auto" src="/logo.png" />
          {admin?.main ? (
            <div className="flex flex-col  pt-8 px-3">
              {/* <Link
                onClick={() => setShow(false)}
                className="flex justify-between items-center border-b py-3 font-[600]"
                to="/dashboard"
              >
                درخواست ها <FaAngleLeft />
              </Link>
              <Link
                onClick={() => setShow(false)}
                className="flex justify-between items-center border-b py-3 font-[600]"
                to="/url"
              >
                مدیریت url ها <FaAngleLeft />
              </Link>
              <Link
                onClick={() => setShow(false)}
                className="flex justify-between items-center border-b py-3 font-[600]"
                to="/logs"
              >
                مشاهده تغییرات در سایت <FaAngleLeft />
              </Link>
              
             */}
              {/* <Link
                onClick={() => setShow(false)}
                className="flex justify-between items-center border-b py-3 font-[600]"
                to="/event"
              >
                مدیریت رویداد ها
                <FaAngleLeft />
              </Link>  */}
              <Link
                onClick={() => setShow(false)}
                className="flex justify-between items-center border-b py-3 font-[600]"
                to="/product"
              >
                مدیریت محصولات
                <FaAngleLeft />
              </Link>
              <Link
                onClick={() => setShow(false)}
                className="flex justify-between items-center border-b py-3 font-[600]"
                to="/project"
              >
                مدیریت پروژه ها
                <FaAngleLeft />
              </Link>
              {/* <Link
                onClick={() => setShow(false)}
                className="flex justify-between items-center border-b py-3 font-[600]"
                to="/blog"
              >
               مدیریت اخبار
                <FaAngleLeft />
              </Link> */}
              {/* <Link
                onClick={() => setShow(false)}
                className="flex justify-between items-center border-b py-3 font-[600]"
                to="/admin"
              >
                مدیریت ادمین ها
                <FaAngleLeft />
              </Link>
              <Link
                onClick={() => setShow(false)}
                className="flex justify-between items-center border-b py-3 font-[600]"
                to="/chat"
              >
                چت با کاربر
                <FaAngleLeft />
              </Link>
              <Link
                onClick={() => setShow(false)}
                className="flex justify-between items-center border-b py-3 font-[600]"
                to="/notification"
              >
                نوتیفیکشن
                <FaAngleLeft />
              </Link>
              <Link
                onClick={() => setShow(false)}
                className="flex justify-between items-center border-b py-3 font-[600]"
                to="/story"
              >
                مدیریت استوری ها
                <FaAngleLeft />
              </Link>
              <Link
                onClick={() => setShow(false)}
                className="flex justify-between items-center border-b py-3 font-[600]"
                to="/category"
              >
                مدیریت دسته بندی ها
                <FaAngleLeft />
              </Link> */}
              <button
                onClick={handleDelete}
                className="flex justify-between items-center border-b py-3 font-[600]"
              >
                خروج
                <FaAngleLeft />
              </button>
            </div>
          ) : (
            <div className="flex flex-col  pt-8 px-3">
              {/* {admin?.forms && (
                <Link
                  onClick={() => setShow(false)}
                  className="flex justify-between items-center border-b py-3 font-[600]"
                  to="/dashboard"
                >
                  درخواست ها <FaAngleLeft />
                </Link>
              )}
              {admin?.url && (
                <Link
                  onClick={() => setShow(false)}
                  className="flex justify-between items-center border-b py-3 font-[600]"
                  to="/url"
                >
                  مدیریت url ها <FaAngleLeft />
                </Link>
              )}
              {admin?.logs && (
                <Link
                  onClick={() => setShow(false)}
                  className="flex justify-between items-center border-b py-3 font-[600]"
                  to="/logs"
                >
                  مشاهده تغییرات در سایت <FaAngleLeft />
                </Link>
              )}
              {admin?.products && (
                <Link
                  onClick={() => setShow(false)}
                  className="flex justify-between items-center border-b py-3 font-[600]"
                  to="/product"
                >
                  مدیریت محصولات
                  <FaAngleLeft />
                </Link>
              )}
              {admin?.admin && (
                <Link
                  onClick={() => setShow(false)}
                  className="flex justify-between items-center border-b py-3 font-[600]"
                  to="/admin"
                >
                  مدیریت ادمین ها
                  <FaAngleLeft />
                </Link>
              )}

              {admin?.brands && (
                <Link
                  onClick={() => setShow(false)}
                  className="flex justify-between items-center border-b py-3 font-[600]"
                  to="/brand"
                >
                  مدیریت برند ها
                  <FaAngleLeft />
                </Link>
              )} */}

              {admin?.blogs && (
                <Link
                  onClick={() => setShow(false)}
                  className="flex justify-between items-center border-b py-3 font-[600]"
                  to="/blog"
                >
                  مدیریت بلاگ ها
                  <FaAngleLeft />
                </Link>
              )}

              {/* {admin?.chat && (
                <Link
                  onClick={() => setShow(false)}
                  className="flex justify-between items-center border-b py-3 font-[600]"
                  to="/chat"
                >
                  چت با کاربر
                  <FaAngleLeft />
                </Link>
              )}

              {admin?.story && (
                <Link
                  onClick={() => setShow(false)}
                  className="flex justify-between items-center border-b py-3 font-[600]"
                  to="/story"
                >
                  مدیریت استوری ها
                  <FaAngleLeft />
                </Link>
              )}

              {admin?.category && (
                <Link
                  onClick={() => setShow(false)}
                  className="flex justify-between items-center border-b py-3 font-[600]"
                  to="/category"
                >
                  مدیریت دسته بندی ها
                  <FaAngleLeft />
                </Link>
              )}
              {admin?.notification && (
                <Link
                  onClick={() => setShow(false)}
                  className="flex justify-between items-center border-b py-3 font-[600]"
                  to="/notification"
                >
                  نوتیفیکشن
                  <FaAngleLeft />
                </Link>
              )} */}

              <button
                onClick={handleDelete}
                className="flex justify-between items-center border-b py-3 font-[600]"
              >
                خروج
                <FaAngleLeft />
              </button>
            </div>
          )}
        </div>
      </>
    );
  }
};

export default Navbar;
