import React, { useContext, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { httpReauest } from "../../utils/httpRequest";
import Loading from "./Loading";
import { priceComma } from "../../utils/priceComma";
import { AuthContext } from "../../context/AuthContext";

const EditPriceList = ({ vehicle }) => {
  const [price, setPrice] = useState(vehicle?.price * 1000000000);
  const [approve, setapprove] = useState();
  const [loading, setloading] = useState();
  const { user, setUser } = useContext(AuthContext);


  async function handleEdit(e) {
    e.preventDefault();
    setloading(true);
    const newPrice = Number(price?.replaceAll(",", "")) / 1000000000;

    await httpReauest(
      "PUT",
      "/vehicle/" + vehicle._id,
      { price: newPrice },
      { "x-access-token": user.token }
    ).then((data) => {
      if (data.status === 201) {
        setapprove(true);
        setloading(false);
      }
    });
  }

  function handleChange(params) {
    const value = params.target.value;
    const formattedValue = (
      Number(value.replace(/\D/g, "")) || ""
    ).toLocaleString();
    setPrice(formattedValue);
  }

  return (
    <tr class="bg-slate-700 border-blue-40">
      <th
        scope="row"
        class="px-6 py-4 font-medium text-blue-50 whitespace-nowrap dark:text-blue-100"
      >
        {vehicle?.name}
      </th>
      <td class="px-6 py-4 relative">
        <input
          type="text"
          name="price"
          dir="ltr"
          id="price"
          onChange={handleChange}
          value={price.toLocaleString()}
          className="form-brand bg-transparent ms-1"
        />{" "}
        <span> تومان </span>
      </td>
      <td class="px-6 py-4">
        {loading ? (
          <Loading />
        ) : approve ? (
          <FaCheckCircle size={25} color="green" />
        ) : (
          <button onClick={handleEdit} className="btn-brand">
            ویرایش
          </button>
        )}
      </td>
    </tr>
  );
};

export default EditPriceList;
