import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import { httpReauest } from "../../../utils/httpRequest";
import * as Yup from "yup";
import { BASE_URL } from "../../../config";
import Loading from "../Loading";
import { AuthContext } from "../../../context/AuthContext";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

const CreateProjectForm = () => {
  const [img, setImg] = useState();
  const [images, setImages] = useState([]);
  const [previews, setPreview] = useState([]);
  const [previewImg, setPreviewImg] = useState();
  const [loading, setloading] = useState();
  const [vehicles, setvehicles] = useState([]);
  const [urls, seturls] = useState([]);
  const [repeatedname, setrepeatedname] = useState();
  const [file, setfile] = useState();
  const [disabled, setdisabled] = useState(false);
  const [errorUrl, seterrorUrl] = useState(false);
  const [details, setdetails] = useState([]);

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      name: Yup.string().required("الزامی است"),
      // price: Yup.string().required("الزامی است"),
    });
  };

  const { user, setUser } = useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      name: "",
      content: "",
      img: "",
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      setloading(true);
      const formdata = new FormData();
      if (!img) {
        return toast.error("لوگو نمی تواند خالی باشد");
      }

      formdata.append("name", values.name);
      formdata.append("content", values.content);
      formdata.append("img", img, img?.name);
      if (images) {
        for (let index = 0; index < images.length; index++) {
          formdata.append("images", images[index], images[index]?.name);
        }
      }

      await fetch(BASE_URL + "/project", {
        method: "POST",
        body: formdata,
        headers: { "x-access-token": user.token },
      }).then((dat) => {
        if (dat?.status == 201) {
          toast.success("پروژه ساخته شد");
          setloading(false);
          formik.resetForm();
          setImg(null);
          setImages(null);
          setPreviewImg(null);
          setPreview(null);
        } else {
          const message = dat.json();
          setloading(false);
          toast.error(message?.message);
        }
      });
    },
  });

  // useEffect(() => {
  //   getData();
  // }, []);

  function handleImg(e) {
    const fils = e.target.files[0];
    if (fils.size > 10031517) {
      toast.error("حجم فایل باید کمتر از ده مگابایت باشد");
    } else {
      setImg(fils);
      setPreviwImage(fils);
    }
  }

  function handleImages(e) {
    const fils = e.target.files;
    if (fils) {
      for (let index = 0; index < fils?.length; index++) {
        if (fils[index].size > 10031517) {
          toast.error("حجم فایل باید کمتر از ده مگابایت باشد");
        } else {
          setImages((current) => [...current, fils[index]]);
          setPreviwImages(fils[index]);
        }
      }
    }
  }

  async function setPreviwImages(i) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreview((current) => [...current, reader.result]);
    };
    reader.readAsDataURL(i);
  }

  async function setPreviwImage(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  // // async function getData() {
  // //   await httpReauest("GET", "/url", {}, {}).then(({ data }) => {
  // //     seturls(data?.data);
  // //   });

  //   // await httpReauest("GET", "/vehicle/getall/dash?onlyName=true", {}, {}).then(
  //   //   ({ data }) => {
  //   //     setvehicles(data?.data);
  //   //   }
  //   // );
  // }

  // function handleChange(params) {
  //   const value = params.target.value;
  //   const formattedValue = (
  //     Number(value.replace(/\D/g, "")) || ""
  //   ).toLocaleString();
  //   formik.setFieldValue("price", formattedValue);
  // }

  // function handleChangeUrl(params) {
  //   const value = params.target.value;
  //   setdetails([]);
  //   formik.values.details = [];
  //   const finded = urls.find((item) => item._id === value);

  //   finded?.details?.map((item) => {
  //     setdetails((cur) => [...cur, { name: item, value: "" }]);
  //     formik.values.details.push({ name: item, value: "" });
  //   });
  //   formik.setFieldValue("url", value);
  // }

  return (
    <div>
      <h2 className="text-[20px] mb-8 font-bold p-3">فرم ایجاد پروژه</h2>
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-6"
      >
        <span className="relative">
          <input
            placeholder="مثلا : فوتون 450"
            id="name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
          />

          {formik.errors.name && formik.touched.name && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.name}
            </small>
          )}
          <p className="on-border font-bold">نام پروژه*</p>
        </span>

        {/* <span className="relative">
          <select
            id="url"
            name="url"
            value={formik.values.url}
            onChange={handleChangeUrl}
            onBlur={formik.handleBlur}
            type="text"
            className="w-full form-brand h-[48px]"
          >
            <option></option>
            {urls?.map((e) => (
              <option value={e._id}>
                {e?.category?.name}/{e?.brand?.name}/{e?.name}
              </option>
            ))}
          </select>
          <p className="on-border font-bold">url*</p>
        </span> */}
        {/* <span className="relative">
          <input
            placeholder="مثلا : 1402"
            type="number"
            id="year"
            name="year"
            value={formik.values.year}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
          />
          {formik.errors.year && formik.touched.year && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.year}
            </small>
          )}
          <p className="on-border font-bold">سال ساخت*</p>
        </span> */}
        {/* <span className="relative">
          <span className="flex justify-between">
            <p className=" font-bold">نمره کارشناسی</p>
            <p>{formik.values?.rate}</p>
          </span>

          <input
            placeholder="مثلا : 1402"
            type="range"
            id="rate"
            name="rate"
            min={0}
            max={100}
            value={formik.values.rate}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className=" w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer "
          />
        </span> */}

        {/* <span className="flex justify-between items-start">
          <span className="text-[#2E3132] text-[16px] font-[500]">
            فروش فوری است؟
          </span>
          <label class="inline-flex items-center cursor-pointer">
            <input
              id="onFire"
              name="onFire"
              value={formik.values.onFire}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="checkbox"
              class="sr-only peer"
            />
            <div class="relative w-11 h-[25px] bg-white peer-focus:outline-none rounded-full peer border peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full border-black after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-black after:border-gray-300 after:border after:rounded-full after:h-[19px] after:w-[19px] after:transition-all dark:border-gray-600 after:peer-checked:bg-white peer-checked:bg-black"></div>
          </label>
        </span> */}
        {/* <span className="relative">
          <select
            id="fit"
            name="fit"
            value={formik.values.fit}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={` w-full form-brand h-[48px]`}
          >
            <option></option>
            <option>خودرو اقتصادی</option>
            <option>خودرو کاری</option>
          </select>
          <p className="on-border font-bold">مناسب؟</p>
        </span> */}
        {/* <span className="relative">
          <select
            id="lazing"
            name="lazing"
            value={formik.values.lazing}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={` w-full form-brand h-[48px]`}
          >
            <option></option>
            <option>لیزینگ دولتی</option>
            <option>لیزینگ خصوصی</option>
            <option>لیزینگ نمایشگاه</option>
          </select>
          <p className="on-border font-bold">نوع لیزینگ؟* </p>
          {formik.errors.lazing && formik.touched.lazing && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.lazing}
            </small>
          )}
        </span> */}

        <label
          htmlFor="img"
          className="relative w-full border-dashed border rounded-[10px] p-3 h-[200px] cursor-pointer border-[#444]"
        >
          {previewImg ? (
            <img
              src={previewImg}
              className="w-full h-full object-contain"
              alt=""
            />
          ) : (
            <FaPlus className="center" size={30} />
          )}

          <p className="on-border font-bold">تصویر اصلی</p>
        </label>
        {/* <span>
          <p className="mb-3 font-bold">فایل برای چک لیست</p>
          <input
            onChange={(e) => {
              setfile(e.target.files[0]);
            }}
            type="file"
          />
        </span> */}
        <input
          onChange={(e) => {
            handleImg(e);
          }}
          type="file"
          className="hidden"
          id="img"
          accept="image/png, image/jpg, image/jpeg"
        />
        {/* <span className="col-span-full">
          <h2 className="text-[18px]  font-bold p-3">مشخصات</h2>
        </span> */}
        {/* {details.length
          ? details?.map((item, index) => (
              <span className="relative">
                <input
                  id={`details[${index}].value`}
                  name={`details[${index}].value`}
                  value={formik.values.details[index]?.value}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full form-brand h-[48px]"
                />
                <p className="on-border font-bold">{item.name}</p>
              </span>
            ))
          : ""} */}
        <span className="col-span-full">
          <ReactQuill
            value={formik.values.content}
            modules={{
              toolbar: [
                [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                ["bold", "italic", "underline", "strike"], // toggled buttons
                ["blockquote", "code-block"],
                ["link", "image", "video", "formula"],

                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ script: "sub" }, { script: "super" }], // superscript/subscript
                [{ indent: "-1" }, { indent: "+1" }], // outdent/indent

                [{ align: [] }],
              ],
            }}
            onChange={(value) => formik.setFieldValue("content", value)}
          />
        </span>
        <label
          htmlFor="images"
          className="relative col-span-full w-full border-dashed border rounded-[10px] p-3 min-h-[340px] cursor-pointer border-[#444]"
        >
          {previews?.length > 0 ? (
            <div
              className="p-3"
              style={{
                position: "absolute",
                top: "0",
                right: "0",
                display: "flex",
                flexWrap: "wrap",
                gap: "15px",
              }}
            >
              {previews &&
                previews.map((src) => {
                  return (
                    <img
                      src={src}
                      style={{
                        width: "150px",
                        height: "150px",
                        objectFit: "contain",
                      }}
                      alt=""
                    />
                  );
                })}
              <FaPlus size={30} />
            </div>
          ) : (
            <FaPlus className="center" size={30} />
          )}

          <p className="on-border font-bold">تصاویر</p>
        </label>
        <input
          onChange={(e) => {
            handleImages(e);
          }}
          type="file"
          className="hidden"
          id="images"
          accept="image/png, image/jpg, image/jpeg"
        />
        {loading ? (
          <Loading />
        ) : (
          <button type="submit" className="btn-brand">
            ارسال
          </button>
        )}
      </form>
    </div>
  );
};

export default CreateProjectForm;
