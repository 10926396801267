import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { CDN_BASE_URL } from "../../../config";
import { httpReauest } from "../../../utils/httpRequest";
import { FaSellcast, FaTrash, FaCheck } from "react-icons/fa";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/AuthContext";

const ProductsCardSelect = ({ data, setVehicles, select, vehicles }) => {
  const { img, name, price, _id, sell, special_price } = data;
  const { user, setUser } = useContext(AuthContext);


  async function handleAdd() {
    const repeat = vehicles?.find((e) => e._id === _id);
    if (!repeat) {
      setVehicles((cur) => [...cur, data]);
    }
  }

  const [selled, setSelled] = useState();
  const [inputprice, setinputprice] = useState(special_price);

  async function handleDelete() {
    setVehicles((students) => students.filter((s) => s._id != data._id));
  }

  async function handleSell(e) {
    e.preventDefault();


    await httpReauest(
      "PUT",
      "/vehicle/" + _id,
      { sell: true },
      {
        "x-access-token": user.token,
      }
    ).then((dat) => {
      if (dat?.status == 201) {
        setSelled(true);
      }
    });
  }

  async function handleSubmit(ed) {
    ed.preventDefault();

    if (!inputprice) {
      return;
    }

    await httpReauest(
      "PUT",
      "/vehicle/" + _id,
      { special_price: inputprice },
      {
        "x-access-token": user.token,
      }
    ).then((dat) => {
      if (dat?.status == 201) {
        toast.success("قیمت اضافه شد");
      }
    });
  }

  if (!select) {
    return (
      <div
        onClick={handleAdd}
        className="border-brand grid grid-cols-12 overflow-hidden cursor-pointer"
      >
        <span className="col-span-5">
          <img
            src={CDN_BASE_URL + img}
            alt={name}
            className="w-full h-full object-cover"
          />
        </span>
        <span className="col-span-7 p-3 flex flex-col gap-3">
          <h3 className="text-[14px] 2xl:text-[16px] font-[600]">{name}</h3>
          <h3 className="text-[16px] 2xl:text-[18px] font-[600] mt-2" dir="ltr">
            میلیارد تومان {price}
          </h3>
        </span>
      </div>
    );
  } else {
    return (
      <div className="border-brand grid grid-cols-12 overflow-hidden  relative">
        <span className="col-span-5">
          <img
            src={CDN_BASE_URL + img}
            alt={name}
            className="w-full h-full object-cover"
          />
        </span>
        <span className="col-span-7 p-3 flex flex-col gap-3">
          <h3 className="text-[14px] 2xl:text-[16px] font-[600]">{name}</h3>
          <h3 className="text-[16px] 2xl:text-[18px] font-[600] mt-2" dir="ltr">
            میلیارد تومان {price}
          </h3>
        </span>

        <div className="col-span-full p-2 flex justify-between">
          <FaTrash
            onClick={handleDelete}
            color="red"
            size={20}
            className=" cursor-pointer"
          />
          {!sell && !selled ? (
            <button
              onClick={handleSell}
              className=" left-10 top-1 cursor-pointer  text-[12px] font-bold bg-green-600 p-1 text-white rounded-md"
            >
              تبدیل به فروخته شد
            </button>
          ) : (
            <FaCheck color="green" className=" top-1" />
          )}
        </div>
        <form className="col-span-full p-2 flex justify-between">
          <span className="relative">
            <input
              value={inputprice}
              onChange={(e) => setinputprice(e.target.value)}
              type="number"
              id="special_price"
              name="special_price"
              className="form-brand"
            />
            <p className="on-border">زیر قیمت</p>
          </span>
          <button onClick={handleSubmit} className="btn-brand">
            تایید
          </button>
        </form>
      </div>
    );
  }
};

export default ProductsCardSelect;
