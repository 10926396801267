import "./App.css";
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import {
  RouterProvider,
  createBrowserRouter,
  useNavigate,
} from "react-router-dom";
import HomePage from "./pages/HomePage";
import Layout from "./components/layout";
import "react-image-gallery/styles/scss/image-gallery.scss";
import "swiper/css";
import LoginPage from "./pages/LoginPage";
import { useEffect, useState } from "react";
import { httpReauest } from "./utils/httpRequest";
import ProductManagePage from "./pages/ProductManagePage";
import BrandManagePage from "./pages/BrandManagePage";
import BlogManagePage from "./pages/BlogManagePage";
import "react-quill/dist/quill.snow.css";
import AdminManagePage from "./pages/AdminManagePage";
import Chat from "./pages/Chat";
import { initiateSocketConnection } from "./helpers/socketHelper";
import StoryManagePage from "./pages/StoryManagePage";
import CategoryManagePage from "./pages/CategoryManagePage";
import NotificationManagePage from "./pages/NotificationManagePage";
import UrlManagePage from "./pages/UrlManagePage";
import { AuthContext } from "./context/AuthContext";
import LogPage from "./pages/LogPage";
import EventManagePage from "./pages/EventManagePage";
import ProjectManagePage from "./pages/ProjectManagePage";

initiateSocketConnection();

function App() {
  useEffect(() => {
    getData();
  }, []);

  const [admin, setAdmin] = useState();
  const [user, setUser] = useState();

  async function getData() {
    if (user) {
      await httpReauest(
        "POST",
        "/admin/islogined",
        {},
        { "x-auth-token": user?.token }
      ).catch(() => {
        window.location.href = window.origin + "/";
        localStorage.removeItem("user");
      });
    } else {
      if (window.location.href != window.origin + "/") {
        window.location.href = window.origin + "/";
      }
    }
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <LoginPage />
        </>
      ),
    },
    // {
    //   path: "/dashboard",
    //   element: (
    //     <>
    //       <Layout admin={admin}>
    //         {(admin?.forms || admin?.main) && <HomePage />}
    //       </Layout>
    //     </>
    //   ),
    // },
    // {
    //   path: "/logs",
    //   element: (
    //     <>
    //       <Layout admin={admin}>
    //         {(admin?.logs || admin?.main) && <LogPage />}
    //       </Layout>
    //     </>
    //   ),
    // },
    // {
    //   path: "/url",
    //   element: (
    //     <>
    //       <Layout admin={admin}>
    //         {(admin?.url || admin?.main) && <UrlManagePage />}
    //       </Layout>
    //     </>
    //   ),
    // },
    // {
    //   path: "/chat",
    //   element: (
    //     <>
    //       <Layout admin={admin}>
    //         <Chat />
    //       </Layout>
    //     </>
    //   ),
    // },
    {
      path: "/product",
      element: (
        <>
          <Layout admin={admin}>
            <ProductManagePage />
          </Layout>
        </>
      ),
    },
    {
      path: "/project",
      element: (
        <>
          <Layout admin={admin}>
            <ProjectManagePage />
          </Layout>
        </>
      ),
    },
    // {
    //   path: "/event",
    //   element: (
    //     <>
    //       <Layout admin={admin}>
    //         <EventManagePage />
    //       </Layout>
    //     </>
    //   ),
    // },
    // {
    //   path: "/story",
    //   element: (
    //     <>
    //       <Layout admin={admin}>
    //         <StoryManagePage />
    //       </Layout>
    //     </>
    //   ),
    // },
    // {
    //   path: "/category",
    //   element: (
    //     <>
    //       <Layout admin={admin}>
    //         <CategoryManagePage />
    //       </Layout>
    //     </>
    //   ),
    // },
    // {
    //   path: "/notification",
    //   element: (
    //     <>
    //       <Layout admin={admin}>
    //         <NotificationManagePage />
    //       </Layout>
    //     </>
    //   ),
    // },
    // {
    //   path: "/admin",
    //   element: (
    //     <>
    //       <Layout admin={admin}>
    //         <AdminManagePage />
    //       </Layout>
    //     </>
    //   ),
    // },
    // {
    //   path: "/brand",
    //   element: (
    //     <>
    //       <Layout admin={admin}>
    //         <BrandManagePage />
    //       </Layout>
    //     </>
    //   ),
    // },
    // {
    //   path: "/blog",
    //   element: (
    //     <>
    //       <Layout admin={admin}>
    //         <BlogManagePage />
    //       </Layout>
    //     </>
    //   ),
    // },
  ]);

  return (
    <AuthContext.Provider value={{ user, setUser, setAdmin }}>
      <RouterProvider router={router} />
    </AuthContext.Provider>
  );
}

export default App;
