import React, { useEffect, useState } from "react";
import { httpReauest } from "../../utils/httpRequest";
import { FaCheck, FaCheckCircle } from "react-icons/fa";
import EditPriceList from "./EditPriceList";

const TableListPrice = () => {
  const [data, setData] = useState([]);
  const [page, setpage] = useState(1);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    await httpReauest("GET", "/vehicle/price/vehicle", {}, {}).then(
      ({ data: data2 }) => {
        setData(data2.data);
      }
    );
  }

  return (
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table class="w-full text-sm text-left rtl:text-right text-blue-100 dark:text-blue-100">
        <thead class="text-xs text-white uppercase bg-slate-800 dark:text-white">
          <tr>
            <th scope="col" class="px-6 py-3">
              اسم خودرو
            </th>
            <th scope="col" class="px-6 py-3">
              قیمت
            </th>
            <th scope="col" class="px-6 py-3">
              بروز رسانی
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((vehicle) => {
            return <EditPriceList vehicle={vehicle} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TableListPrice;
