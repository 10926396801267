import React, { useContext, useEffect, useState } from "react";
import { httpReauest } from "../../../utils/httpRequest";
import ProductsCard from "../card/ProductsCard";
import ProductsCardSelect from "../card/ProductsCardSelect";
import { BASE_URL } from "../../../config";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/AuthContext";

const EditTimerProduct = () => {
  const [vehcledata, setvehcledata] = useState([]);
  const [hours, sethours] = useState();
  const [vehicles, setVehicles] = useState();

  console.log(vehcledata);

  useEffect(() => {
    stayLogin();
  }, []);

  async function stayLogin() {
    await httpReauest("GET", "/vehicle/timer", {}, {}).then(({ data }) => {
      setvehcledata(data?.data?.vehicles);
      sethours(data?.data?.hour);
    });

    await httpReauest("GET", "/vehicle/getall/dash", {}, {}).then(
      ({ data }) => {
        setVehicles(data?.data);
      }
    );
  }

  const { user, setUser } = useContext(AuthContext);

  async function handleSubmit(e) {
    e.preventDefault();
    const arrat = [];

    await vehcledata?.map((e) => {
      arrat.push(e._id);
    });

    await httpReauest(
      "POST",
      "/vehicle/timer",
      { hours, vehicles: arrat },
      { "x-access-token": user.token }
    )
      .then((dat) => {
        if (dat?.status == 201) {
          toast.success("موفقیت آمیز");
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  }
  return (
    <div>
      <h2 className="text-[20px] mb-8 font-bold p-3">فرم تغییر تایمر</h2>{" "}
      <form className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-6">
        <span className="relative">
          <input
            placeholder="مثلا : 4"
            id="hours"
            name="hours"
            type="number"
            value={hours}
            onChange={(e) => sethours(e.target.value)}
            // onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
          />
          {/* {formik.errors.name && formik.touched.name && (
                <small style={{ color: "red", fontSize: "14px" }}>
                  {formik.errors.name}
                </small>
              )} */}
          <p className="absolute translate-y-[-50%] top-[24px] left-4">ساعت</p>
          <p className="on-border font-bold">ساعت تایمر</p>
        </span>
        <span className="col-span-full border-brand p-4 relative grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-3 gap-5">
          <p className="on-border font-bold">
            محصولات انتخاب شده برای زیر قیمت
          </p>
          {vehcledata?.map((d) => {
            return (
              <ProductsCardSelect select data={d} setVehicles={setvehcledata} />
            );
          })}
        </span>
        <span>
          <button onClick={handleSubmit} className="btn-brand">
            تایید
          </button>
        </span>
        <span className="col-span-full p-7 relative grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-3 gap-5">
          <p className="on-border font-bold">انتخاب محصول برای زیر قیمت</p>
          {vehicles?.map((d) => {
            return (
              <ProductsCardSelect
                data={d}
                setVehicles={setvehcledata}
                vehicles={vehcledata}
              />
            );
          })}
        </span>
      </form>
    </div>
  );
};

export default EditTimerProduct;
