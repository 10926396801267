import React from "react";
import Modal from "react-modal";

const customStyles = {
  overlay: {
    zIndex: "9000",
    background: "#00000050",
  },
};

Modal.setAppElement("#root");

const CustomModal = ({ show, onHide, children }) => {
  return (
    <Modal style={customStyles} isOpen={show} onRequestClose={onHide}>
      <div dir="rtl">{children}</div>
    </Modal>
  );
};

export default CustomModal;
